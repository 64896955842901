import React, { FC, useEffect, useState } from "react";
import { createContext, useContextSelector } from 'use-context-selector';

type TLayoutContext = {
    isMobile: boolean
}

export const LayoutContext = createContext<TLayoutContext>( {
    isMobile: false,
} )

export const LayoutContextProvider: FC = ( { children } ) => {
    const [ isMobile, setIsMobile ] = useState( false );

    useEffect( () => {
        let defaultMobile = window.matchMedia( "(min-width: 85em)" ).matches
        setIsMobile( !defaultMobile )

        function toggleIsMobile( e: MediaQueryListEvent ) {
            setIsMobile( !e.matches )
        }

        window.matchMedia( "(min-width: 85em)" ).addEventListener( 'change', toggleIsMobile );
        return () => {
            window.matchMedia( "(min-width: 85em)" ).removeEventListener( 'change', toggleIsMobile );
        }
    }, [ isMobile ] )


    return (
        <LayoutContext.Provider value={ {
            isMobile,
        } }>
            { children }
        </LayoutContext.Provider>
    )
}

export const useIsMobile = () => useContextSelector( LayoutContext, s => s.isMobile )
